<template>
  <MDBContainer class="text-center login-area p-2">
    <div class="form p-5">
      <MDBRow>
        <MDBCol >
          <h4>仁安訂閱</h4>
          <h4>管理系統</h4>
        </MDBCol>
      </MDBRow>
      <MDBRow tag="form"  class="g-3" @submit="Login">
        <MDBCol md="12">
          <MDBInput
            :label="$t('COMMON.EMAIL')"
            type="text"
            v-model="Form.email.value"
            name="email"
          />
          <span class="text-danger">{{ emailError }}</span>
        </MDBCol>
        <MDBCol md="12">
          <MDBInput
            :label="$t('COMMON.PASSWORD')"
            type="password"
            v-model="Form.password.value"
          />
          <span class="text-danger">{{ passwordError }}</span>
        </MDBCol>
        <div class="input-group captcha">
          <input type="text" class="form-control" :placeholder="$t('COMMON.CAPTCHA')" aria-describedby="captha-area" v-model="Form.captcha.value">
          <span class="input-group-text" id="captha-area">
            <img :src="captchaImg" @click="Captcha()">
          </span>
        </div>
        <span class="text-danger">{{ captchaError }}</span>
        <MDBCol md="12">
          <MDBBtn class="login-btn" color="success" type="submit">
            <span>登入</span>
            <span class="spinner-border Loading d-none" role="status">
              <span class="visually-hidden">Loading...</span>
            </span>
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </div>
  </MDBContainer>
</template>

<style scoped>
  @media screen and (max-width: 576px){
    .login-area{
        width: 350px;
    }
  }
  @media screen and (min-width: 576px){
    .login-area{
        width: 350px;
    }
  }
  @media screen and (min-width: 768px){
    .login-area{
        width: 435px;
    }
  }
  @media screen and (min-width: 992px){
    .login-area{
        width: 435px;
    }
  }
  @media screen and (min-width: 1200px){
    .login-area{
        width: 435px;
    }
  }
  .login-area {
    border-radius: 5px;
    background-color: #fff;
  }
  .login-area .form {
    box-shadow: 0 0px 24px 0 rgb(0 0 0 / 6%), 0 1px 0px 0 rgb(0 0 0 / 2%);
    border: 5px solid #64b0f2;
    border-radius: 5px;
  }
  .captcha img{
    cursor: pointer;
  }
  .captcha input {
    height: auto;
  }
  .login-btn {
    width: 100%;
    background-color: #1bb99a;
  }
  .login-btn .Loading {
    margin-left: 5px;
    position: unset;
    height: 1rem;
    width: 1rem;
  }
</style>

<script>
import { MDBInput, MDBContainer, MDBRow, MDBBtn, MDBCol } from 'mdb-vue-ui-kit';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import ApiService from "@/core/services/api.service";

export default {
  name: "LoginForm",
  components: {
    MDBInput,
    MDBContainer,
    MDBRow,
    MDBBtn,
    MDBCol
  },
  setup() {
    const i18n = useI18n();

    const captchaForm = {
        captcha_key: "",
      };

    const captchaImg = ref("");

    const Captcha = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/Captcha",
          {}
        ).then(response => {
          if (response.status == 200) {
            captchaImg.value = response.data.data.img;
            captchaForm.captcha_key = response.data.data.key;
            resolve(response);
          }
        });
      });
    };

    const schema = yup.object({
      email: yup.string().required().email().max(50).label(i18n.t("COMMON.EMAIL")),
      password: yup.string().required().min(6).max(12).label(i18n.t("COMMON.PASSWORD")),
      captcha: yup.string().required().label(i18n.t("COMMON.CAPTCHA")),
    });

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: email, errorMessage: emailError } = useField('email');
    const { value: password, errorMessage: passwordError } = useField('password');
    const { value: captcha, errorMessage: captchaError } = useField('captcha');

    const store = useStore();
    const router = useRouter();

    const Login = handleSubmit(values => {
      Promise.all([
        document.querySelector('.login-btn .Loading').classList.remove('d-none'),
        store
          .dispatch("login", Object.assign(values,captchaForm))
          .then(() => {
            router.push({ name: "Dashboard" });
          })
          .catch(() => {
            Captcha();
          }),
      ]).then(() => {
          document.querySelector('.login-btn .Loading').classList.add('d-none');
        }
      );
      
      return false;
    });

    Captcha();

    return {
      Form: {
        email,
        password,
        captcha
      },
      emailError,
      passwordError,
      captchaError,
      Login,
      captchaForm,
      captchaImg,
      Captcha
    };
  }
};
</script>