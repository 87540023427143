<template>
  <div class="login_bg">
    <LoginForm></LoginForm>
  </div>
</template>

<style scoped>
  .login_bg {
    background: url('/assets/backgroud/login_bg.jpeg');
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
  }
</style>

<script>
import LoginForm from '@/components/LoginForm';

export default {
  name: "Login",
  components: {
		LoginForm
  }
};
</script>